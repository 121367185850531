.canvas__container {
  background-repeat: repeat;
}

.ca1__speaker {
  width: 300px;
  position: absolute;
  top: 10px;
  left: 30px;
}

.ca2__speaker {
  width: 500px;
  position: absolute;
  top: 10px;
  left: 300px;
}

.ca3__speaker {
  width: 350px;
  position: absolute;
  top: 250px;
  left: 300px;
}

.ca4__speaker {
  width: 350px;
  position: absolute;
  top: 500px;
  left: 1000px;
}
.ca5__speaker {
  width: 500px;
  position: absolute;
  top: 50px;
  left: 1500px;
}

.ca6__speaker {
  width: 350px;
  position: absolute;
  top: 10px;
  left: 1000px;
  z-index: -1;
}
.ca7__speaker {
  width: 500px;
  position: absolute;
  top: 50px;
  left: 2000px;
}

 {
  /* Visit Store Style*/
}

a.visit-store {
  position: relative;
  display: inline-block;
  padding: 25px 30px;
  margin: 40px 0;
  color: #03e9f4;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  margin-right: 50px;
}
a.visit-store:hover {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 200px #03e9f4;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}
a.visit-store:nth-child(1) {
  filter: hue-rotate(270deg);
}
a.visit-store:nth-child(2) {
  filter: hue-rotate(110deg);
}
a.visit-store span {
  position: absolute;
  display: block;
}
a.visit-store span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: animate1 1s linear infinite;
}
@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
a.visit-store span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: animate2 1s linear infinite;
  animation-delay: 0.25s;
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
a.visit-store span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: animate3 1s linear infinite;
  animation-delay: 0.5s;
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

a.visit-store span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: animate4 1s linear infinite;
  animation-delay: 0.75s;
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.loading {
  height: 100%;
}

.desktop-loader {
  --progress-bar-width: 35%;
  --progress-bar-margin-top:75px;

  --drag-to-explore-width: 35%;
  --drag-to-explore-margin-top:75px;
}

.mobile-loader {
  --progress-bar-width: 50%;
  --progress-bar-margin-top:40px;

  --drag-to-explore-width: 75%;
  --drag-to-explore-margin-top:40px;
}

.loading img {
  width: 20vmax;
}

.loading .drag-to-explore {
  object-fit: cover;
  margin-top: var(--drag-to-explore-margin-top);
  width: var(--drag-to-explore-width);
}

.loading .progress {
  width: var(--progress-bar-width);
  margin-top: var(--progress-bar-margin-top);

  height: 0.25rem;

  background-color: #494949;
}

.progress .progress-bar {
  background-color: white;
}

#particles-js canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.particles-loading {
  display: none;
}

.fade-in-logo {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

#particles-js canvas {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.fade-in-progess-bar {
  animation: fadeInFast 25s;
  -webkit-animation: fadeInFast 25s;
  -moz-animation: fadeInFast 25s;
  -o-animation: fadeInFast 25s;
  -ms-animation: fadeInFast 25s;
}

.fade-in-drag-to-explore {
  animation: fadeInFast 30s;
  -webkit-animation: fadeInFast 30s;
  -moz-animation: fadeInFast 30s;
  -o-animation: fadeInFast 30s;
  -ms-animation: fadeInFast 30s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInFast {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@-moz-keyframes fadeInFast {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@-webkit-keyframes fadeInFast {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@-o-keyframes fadeInFast {
  0% { opacity: 0; }
  50% { opacity: 1; }
}

@-ms-keyframes fadeInFast {
  50% { opacity: 0; }
  50% { opacity: 1; }
}

.height100 {
  height: 100% !important;
  width: 100% !important;
}