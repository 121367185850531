.login-page {
    background-color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    flex: 1;
    max-width: 400px;
}